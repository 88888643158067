.bg {
    background-image: url(../../img/signin.JPG);
    background-size: cover;
    background-position: top center;
    min-height: 100vh;
    padding: 10px;
}

.card {
    padding: 50px 10px 20px 10px;
    margin: auto;
    max-width: 600px;
}

.header-login {
    box-shadow: 0 16px 38px -12px rgba(0,0,0,0.56), 0 4px 25px 0px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2);
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-top: -40px;
    padding: 20px 0;
    background: linear-gradient(45deg, #6F7DBE, #B0B8DB);
    text-align: center;
}

h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    color: #ffffff;
}

h3 {
    margin-top: 10px;
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
}

.icon-line {
    margin-top: 15px;
    text-align: center;
}

.btn {
    background-color: #6F7DBE;
    color: #ffffff;
    min-height: 50px;
}

.btn:hover {
    background-color: #ffffff;
    color: #6F7DBE;
    border-color: #6F7DBE;
}

.footer {
    margin: auto;
    width: 30%;
}