.ant-form-vertical .ant-form-item-control .picture {
    height: 98px;
    padding: 16px;
    margin: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

.my-picture {
    border: 1px solid #d9d9d9;

    margin-bottom: 2px;
    padding: 8px;
    background-color: #fafafa;
    position: relative;
}

.my-img {
    width: 300px;
    height: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
}

.top-corner {
    position: absolute;
    top: -10px;
    right: -10px;
}

@media only screen and (max-width: 600px) {
    .my-img-mobile { width: 100% }
}