@import url(https://fonts.googleapis.com/css?family=Prompt&display=swap);
.App {
  text-align: center;
}

body {
  margin: 0;
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.background {
    /* Set rules to fill background */
  min-height: 50%;
  min-width: 1024px;
	
  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
	
  /* Set up positioning */
  position: fixed;
  top: 50%;
  left: 0;
    /* background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
    padding: 0px; */
}

.content {
    padding: 0px;
}

.ant-card-meta-description {
  white-space: nowrap; 
  width: 100%; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-default {
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .pagination-top { display: none;}
  .block { width: 100% }
  .title-top { text-align: center; }
}

.ck-editor__editable_inline {
  min-height: 400px;
}

.ant-card-meta-title {
  font-size: 14px;
}

.ant-card-meta-description {
  font-size: 12px;
}

/* .ck-file-dialog-button {
  display: none;
} */
.ant-card-meta-description {
    white-space: nowrap; 
    width: 100%; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.title-default {
    text-align: left;
}

@media only screen and (max-width: 600px) {
    .pagination-top { display: none;}
    .block { width: 100% }
    .title-top { text-align: center; }
}

.ck-editor__editable_inline {
    min-height: 400px;
}

.ant-card-meta-title {
    font-size: 14px;
}

.ant-card-meta-description {
    font-size: 12px;
}

/* 
.ck-file-dialog-button {
    display: none;
} */
.ant-form-vertical .ant-form-item-control .picture {
    height: 98px;
    padding: 16px;
    margin: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

.my-picture {
    border: 1px solid #d9d9d9;

    margin-bottom: 2px;
    padding: 8px;
    background-color: #fafafa;
    position: relative;
}

.my-img {
    width: 300px;
    height: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
}

.top-corner {
    position: absolute;
    top: -10px;
    right: -10px;
}

@media only screen and (max-width: 600px) {
    .my-img-mobile { width: 100% }
}
.ant-card-meta-description {
    white-space: nowrap; 
    width: 100%; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.title-default {
    text-align: left;
}

@media only screen and (max-width: 600px) {
    .pagination-top { display: none;}
    .block { width: 100% }
    .title-top { text-align: center; }
}

.ck-editor__editable_inline {
    min-height: 400px;
}

.ant-card-meta-title {
    font-size: 14px;
}

.ant-card-meta-description {
    font-size: 12px;
}

/* .ck-file-dialog-button {
    display: none;
} */
.ant-card-meta-description {
    white-space: nowrap; 
    width: 100%; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.title-default {
    text-align: left;
}

@media only screen and (max-width: 600px) {
    .pagination-top { display: none;}
    .block { width: 100% }
    .title-top { text-align: center; }
}

.ck-editor__editable_inline {
    min-height: 400px;
}

/* .ck-file-dialog-button {
    display: none;
} */

.ant-radio-wrapper {
    margin-right: 12px;
}

.ant-card-meta-title {
    font-size: 14px;
}

.ant-card-meta-description {
    font-size: 12px;
}
.ant-card-meta-description {
    white-space: nowrap; 
    width: 100%; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.title-default {
    text-align: left;
}

@media only screen and (max-width: 600px) {
    .pagination-top { display: none;}
    .block { width: 100% }
    .title-top { text-align: center; }
}

.ant-card-meta-title {
    font-size: 14px;
}

.ant-card-meta-description {
    font-size: 12px;
}

/* 
.ck-file-dialog-button {
    display: none;
} */
.ant-card-meta-description {
    white-space: nowrap; 
    width: 100%; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.title-default {
    text-align: left;
}

@media only screen and (max-width: 600px) {
    .pagination-top { display: none;}
    .block { width: 100% }
    .title-top { text-align: center; }
}

.ant-card-meta-title {
    font-size: 14px;
}

.ant-card-meta-description {
    font-size: 12px;
}

/* 
.ck-file-dialog-button {
    display: none;
} */
.ant-card-meta-description {
    white-space: nowrap; 
    width: 100%; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.title-default {
    text-align: left;
}

@media only screen and (max-width: 600px) {
    .pagination-top { display: none;}
    .block { width: 100% }
    .title-top { text-align: center; }
}

.ck-editor__editable_inline {
    min-height: 400px;
}

/* .ck-file-dialog-button {
    display: none;
} */

.ant-radio-wrapper {
    margin-right: 12px;
}

.ant-card-meta-title {
    font-size: 14px;
}

.ant-card-meta-description {
    font-size: 12px;
}

.btn-margin {
    min-width: 200px;
    min-height: 200px;
    margin: 20px;
}

.center {
    margin-left: auto;
    margin-right: auto;
}

.facebook {
    background-color: #3578E5;
    color: white;
}

.youtube {
    background-color: #db4437;
    color: white;
}

.twitter {
    background-color: #1da1f2;
    color: white;
}

.game-score {
    background-color: rgba(0, 0, 0, 0.65);
    color: white;
}

.game-medel {
    background-color: #ba2b50;
    color: white;
}

.game-medel-rank {
    background-color: #ffc107;
    color: white;
}
.sider {
    padding: 15px 5px 10px 5px;
    height: 80px;
    text-align: center;
    color: #fff
}

.content {
    padding: 22px;
    margin: 10px 0px 10px 0px;
    background: #fff;
    min-height: 280px
}

.layout {
    min-height: 100vh;
}

.header {
    background: #fff;
    padding: 0px;
}
.bg {
    background-image: url(/static/media/signin.5871a35b.JPG);
    background-size: cover;
    background-position: top center;
    min-height: 100vh;
    padding: 10px;
}

.card {
    padding: 50px 10px 20px 10px;
    margin: auto;
    max-width: 600px;
}

.header-login {
    box-shadow: 0 16px 38px -12px rgba(0,0,0,0.56), 0 4px 25px 0px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2);
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-top: -40px;
    padding: 20px 0;
    background: -webkit-linear-gradient(45deg, #6F7DBE, #B0B8DB);
    background: linear-gradient(45deg, #6F7DBE, #B0B8DB);
    text-align: center;
}

h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    color: #ffffff;
}

h3 {
    margin-top: 10px;
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
}

.icon-line {
    margin-top: 15px;
    text-align: center;
}

.btn {
    background-color: #6F7DBE;
    color: #ffffff;
    min-height: 50px;
}

.btn:hover {
    background-color: #ffffff;
    color: #6F7DBE;
    border-color: #6F7DBE;
}

.footer {
    margin: auto;
    width: 30%;
}
