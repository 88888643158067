.sider {
    padding: 15px 5px 10px 5px;
    height: 80px;
    text-align: center;
    color: #fff
}

.content {
    padding: 22px;
    margin: 10px 0px 10px 0px;
    background: #fff;
    min-height: 280px
}

.layout {
    min-height: 100vh;
}

.header {
    background: #fff;
    padding: 0px;
}