.background {
    /* Set rules to fill background */
  min-height: 50%;
  min-width: 1024px;
	
  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
	
  /* Set up positioning */
  position: fixed;
  top: 50%;
  left: 0;
    /* background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
    padding: 0px; */
}

.content {
    padding: 0px;
}

.ant-card-meta-description {
  white-space: nowrap; 
  width: 100%; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-default {
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .pagination-top { display: none;}
  .block { width: 100% }
  .title-top { text-align: center; }
}

.ck-editor__editable_inline {
  min-height: 400px;
}

.ant-card-meta-title {
  font-size: 14px;
}

.ant-card-meta-description {
  font-size: 12px;
}

/* .ck-file-dialog-button {
  display: none;
} */