.ant-card-meta-description {
    white-space: nowrap; 
    width: 100%; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.title-default {
    text-align: left;
}

@media only screen and (max-width: 600px) {
    .pagination-top { display: none;}
    .block { width: 100% }
    .title-top { text-align: center; }
}

.ck-editor__editable_inline {
    min-height: 400px;
}

/* .ck-file-dialog-button {
    display: none;
} */

.ant-radio-wrapper {
    margin-right: 12px;
}

.ant-card-meta-title {
    font-size: 14px;
}

.ant-card-meta-description {
    font-size: 12px;
}

.btn-margin {
    min-width: 200px;
    min-height: 200px;
    margin: 20px;
}

.center {
    margin-left: auto;
    margin-right: auto;
}

.facebook {
    background-color: #3578E5;
    color: white;
}

.youtube {
    background-color: #db4437;
    color: white;
}

.twitter {
    background-color: #1da1f2;
    color: white;
}

.game-score {
    background-color: rgba(0, 0, 0, 0.65);
    color: white;
}

.game-medel {
    background-color: #ba2b50;
    color: white;
}

.game-medel-rank {
    background-color: #ffc107;
    color: white;
}