@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

@import url('https://fonts.googleapis.com/css?family=Prompt&display=swap');

body {
  margin: 0;
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}